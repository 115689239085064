import {authAxios, apiAxios} from '../helpers/interceptors'
var CryptoJS = require("crypto-js");

const secret = "ad6187a700a33a7eb5a8d0bc302cf6d67db9af3a"

export default {
  state: {
    isAuthenticated: false,
    passwordResetRequested: false,
    accessToken: undefined,
    refreshToken: undefined,
    profile: undefined,
    intervalId: undefined,
    cloudPassword: undefined,
  },
  mutations: {
    isAuthenticated: (state, value) => state.isAuthenticated = value,
    passwordResetRequested: (state, value) => state.passwordResetRequested = value,
    accessToken: (state, value) => state.accessToken = value,
    refreshToken: (state, value) => state.refreshToken = value,
    profile: (state, value) => state.profile = value,
    intervalId: (state, value) => state.intervalId = value,
    cloudPassword: (state, value) => state.cloudPassword = value,
    reset: (state) => {
      clearInterval(state.intervalId);
      Object.keys(state).forEach(key => {
        // console.log('reset ',key,': ',state[key])
        state[key]=undefined
      })
      state.isAuthenticated = false
    },
  },
  getters: {
    isAuthenticated: state => state.isAuthenticated,
    passwordResetRequested: state => state.passwordResetRequested,
    accessToken: state => state.accessToken,
    refreshToken: state => state.refreshToken,
    profile: state => state.profile,
    cloudPassword: state => {
      const decryptedText = CryptoJS.AES.decrypt(state.cloudPassword, secret).toString(CryptoJS.enc.Utf8)
      return decryptedText
    },
  },
  actions: {
    async intervalIdFunc({commit},id) {
      commit('intervalId',id)
    },
    async register(context,payload) {
      const res = await apiAxios.post('/auth/create_user',payload)
      if(res.status==201){
        // console.log('Registration done!')
        return true
      }
      return res.statusText
    },
    async login(context,data) {
      const res = await authAxios.post('/login',{"email": data.email,"password": data.password})
      if(res.status==500){
        window.localStorage.setItem('service_status','unstable')
      }
      if(res.status==200){
      window.localStorage.setItem('service_status','stable')
        context.commit("reset")
        context.rootState.alignment.reset
        context.rootState.calc.reset
        context.rootState.config.reset
        context.rootState.monetize.reset
        context.rootState.vector.reset
        
        context.commit('isAuthenticated', true)
        context.commit('accessToken', res.data["access_token"])
        context.commit('refreshToken', res.data["refresh_token"])
        // console.log('Login done!')

        const encryptedText = CryptoJS.AES.encrypt(data.password, secret).toString()
        context.commit('cloudPassword', encryptedText)
        return true
      }
      return res.data.message
    },
    async refresh({ commit, dispatch }) {
      const res = await authAxios.post("/refresh",{"refresh_token": this.getters.refreshToken})
      if(res.status==500){
        window.localStorage.setItem('service_status','unstable')
      }
      if(res.status==200){
      window.localStorage.setItem('service_status','stable')
        // console.log('refresh res',res)
        commit('isAuthenticated', true)
        commit('accessToken', res.data["access_token"])
        commit('refreshToken', res.data["refresh_token"])
        // console.log('Token refreshed')
        return true
      }
      if(await dispatch("logout")){
        // console.log('Logged out')
      }
      return res.statusText
    },
    async passwordResetRequest(context,email) {
      const res = await authAxios.post("/password-reset-request",{"email": email})
      if(res.status==201){
        context.commit('passwordResetRequested',true)
        return true
      }
      return res.statusText
    },
    async passwordReset(context,data) {
      const res = await authAxios.post("/password-reset",{"email": data.email,"reset_token": data.reset_token,"password": data.password})
      if(res.status==204){return true}
      return res.statusText
    },
    async getProfile({ commit }) {
      try {
        const res = await apiAxios.get("bearer")
        if(res.status==500){
          window.localStorage.setItem('service_status','unstable')
        }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          commit('profile', res.data.token)
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async updateProfile(context, profile) {
      console.log('data sent update profile: ', profile)
      try {
        const res = await apiAxios.put("auth/update_user", profile)
        if(res.status==500){
          window.localStorage.setItem('service_status','unstable')
        }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async logout(context) {
      try {
        context.commit("reset")
        context.rootState.alignment.reset
        context.rootState.calc.reset
        context.rootState.config.reset
        context.rootState.monetize.reset
        context.rootState.vector.reset
        return true
      } catch (error) {
        console.log(error)
        return false
      }
    },
    // clearAll({ commit }){
    //   commit("moduleA/reset")
    //   commit("moduleC/reset")
    // },
    async loginCloud() {
      // const res = await apiAxios.post('/cloud/login',{"password": 'password_errado'})
      const res = await apiAxios.post('/cloud/login',{"password": this.getters.cloudPassword})
      if(res.status==500){
        window.localStorage.setItem('service_status','unstable')
      }
      if(res.status==200){
      window.localStorage.setItem('service_status','stable')
        return true
      }
      return res.statusText
    },
    setCloudPassword({commit},password){
      const encryptedText = CryptoJS.AES.encrypt(password, secret).toString()
      commit('cloudPassword',encryptedText)
    }
  }
}
