import {apiAxios} from '../helpers/interceptors'
import store from '../store'

export default {
  state: {
    baseLayers: undefined,
    track: undefined,
    limit: undefined,
    alternatives: [],
    alternativesDetail: undefined,
    vectorsType: undefined,
    vectors: {},
    buffer: undefined,
    updateVectorsByType: undefined,
    alternativePolygon: undefined,
    storagedProps: {},
    validationStoragedProps: {},
    corridor_1: undefined,
    alternativesStatus: undefined,
  },
  mutations: {
    baseLayers: (state, value) => state.baseLayers = value,
    corridor_1: (state, value) => state.corridor_1 = value,
    track: (state, value) => state.track = value,
    limit: (state, value) => state.limit = value,
    updateTrack (state, points) {state.track = points},
    updateLimit (state, polygon) {state.limit = polygon},
    alternatives: (state, value) => state.alternatives = value,
    alternativesStatus: (state, value) => state.alternativesStatus = value,
    alternativesDetail: (state, value) => state.alternativesDetail = value,
    vectorsType: (state, value) => state.vectorsType = value,
    vectors: (state, value) => state.vectors = value,
    buffer: (state, value) => state.buffer = value,
    updateVectorsByType: (state, value) => state.updateVectorsByType = value,
    alternativePolygon: (state, value) => state.alternativePolygon = value,
    storagedProps: (state, value) => state.storagedProps = value,
    validationStoragedProps: (state,value) => state.validationStoragedProps = value,
    reset: (state) => {
      Object.keys(state).forEach(key => {
        // console.log('reset ',key,': ',state[key])
        state[key]=undefined
      })
    }
  },
  getters: {
    baseLayers: state => state.baseLayers,
    corridor_1: state => state.corridor_1,
    baseLayer: (state) => (idx) => { return state.baseLayers[idx] },
    track: state => state.track,
    limit: state => state.limit,
    alternatives: state => {
      if(state.alternatives && Object.keys(state.alternatives).length > 0){
        Object.values(state.alternatives).forEach((item,idx) => {
          if(item.price==0){ delete state.alternatives[idx+1] }
          else {
            item.priceFormatted = Math.round(item.price/1000).toLocaleString()
            item.lengthFormatted = Math.round(item.length/1000).toLocaleString()+'Km'
            if(item.status=='Calculating'){item.loading = true}
            else{item.loading = false}
          }
        })
        return state.alternatives
      }
    },
    alternativesStatus: state => state.alternativesStatus,
    alternativesDetail: state => state.alternativesDetail,
    vectorsType: state => state.vectorsType,
    vectors: state => state.vectors,
    buffer: state => state.buffer,
    updateVectorsByType: state => state.updateVectorsByType,
    alternativePolygon: state => state.alternativePolygon,
    storagedProps: state => state.storagedProps,
    validationStoragedProps: state => state.validationStoragedProps,
  },
  actions: {
    setBaseLayers({commit}){
      commit('baseLayers',[
        {id: 0, name: 'xyz', title: 'Open Topo Map', attributions: '<a href="https://wiki.openstreetmap.org/wiki/OpenTopoMap">Open Topo Map</a>', url: "https://tile.opentopomap.org/{z}/{x}/{y}.png", visible: false},
        {id: 1, name: 'xyz', title: 'World Hillshage ArcGIS', attributions: '<a href="https://goto.arcgis.com/termsofuse/viewtermsofuse">World Hillshage ArcGIS</a>', url: "http://services.arcgisonline.com/ArcGIS/rest/services/Elevation/World_Hillshade/MapServer/tile/{z}/{y}/{x}", visible: false},
        {id: 2, name: 'xyz', title: 'Google Aerial', attributions: '<a href="https://www.google.com/intl/pt-BR_ALL/permissions/geoguidelines/">Google Aerial</a>', url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", visible: false},
        {id: 3, name: 'bingmaps', title: 'Bing Aerial With Labels', apiKey: 'ArbsA9NX-AZmebC6VyXAnDqjXk6mo2wGCmeYM8EwyDaxKfQhUYyk0jtx6hX5fpMn', imagerySet: 'AerialWithLabels', visible: false},
        {id: 4, name: 'osm', title: 'Open Street Maps', visible: true},
        {id: 5, name: 'xyz', title: 'Expropriation Map', attributions: '<a href="http://dev.wwcalc.com/">Expropriation Maps</a>', url: "http://dev.wwcalc.com/raster/tiles/{z}/{x}/{y}.png", visible: false},
      ])
    },
    async getLayers({commit},calcId){
      try {
        const res = await apiAxios.get('get_layers/'+calcId)
        if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          let alternativesDetail = []
          res.data.forEach(item => {
            if(item.name=='limit'){
              // console.log('limit',item)
              commit('limit',item)
            } else {
              if(item.name=='track'){
                // console.log('track',item)
                commit('track',item)
              } else {
                if(item.type=='alternative_polygon'){
                  commit('alternativePolygon', item)
                } else {
                  if(item.category=='alternative_horizontal'){
                    alternativesDetail.push(item)
                  }else{
                    if(item.name=="corridor_1"){
                      commit('corridor_1', item)
                    }
                  }
                }
              } 
            }
          })
          commit('alternativesDetail',alternativesDetail)
          return true
        }
        return res.statusText
      } catch (error) {
        // alert(error)
        console.log(error)
        return false
      }
    },
    async clearAtHome({commit}){
      commit('corridor_1', {})
    },
    async getAlternatives({commit},calcId){
      try {
        if(calcId != undefined){
          const res = await apiAxios.get('alternatives/'+calcId)
          if(res.status==500){
                  window.localStorage.setItem('service_status','unstable')
                }
          if(res.status==200){
          window.localStorage.setItem('service_status','stable')
            let dict = {}
            res.data.forEach(item => {
              item.number = item.folder.split('_')[1]
              dict[item.number] = item
            })
            commit('alternatives',dict)
            commit('alternativesStatus',dict)
            return true
          }
          return res.statusText
        }
      } catch (error) {
        // alert(error)
        console.log(error)
        return false
      }
    },
    changeLoadAlternative({commit},number){
      commit('changeLoadAlternative',number)
    },
    async updateTrack({commit},{calcId,payload}){
      try {
        const res = await apiAxios.post('layer_multi_point/'+calcId,payload)
        if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          commit('updateTrack',res.data)
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async updateLimit({commit},{calcId,payload}){
      try {
        const res = await apiAxios.post('layer_polygon/'+calcId,payload)
        if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          commit('updateLimit',res.data)
          return true
        }
        return res.statusText
      } catch (error) {
        alert(error)
        console.log(error)
        return false
      }
    },

    async updateAlternativePolygon({commit},{calcId,payload}){
      // console.log(calcId,payload)
      try {
        const res = await apiAxios.patch('layer_polygon/'+calcId+'/',payload)
        if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          commit('alternativePolygon', store.getters.alternativePolygon)
          return true
        }
        return res.statusText
      } catch (error) {
        alert(error)
        console.log(error)
        return false
      }
    },

    async getVectorsType({commit},calcId){
      try {
        const res = await apiAxios.get('vectors/'+calcId)
        if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          const vectorsTypes = res.data;
          commit('vectorsType',vectorsTypes)
          // console.log('Res: ', vectorsTypes)
          return true
        }
        return res.statusText
      } catch (error) {
        // alert(error)
        console.log(error)
        return false
      }
    },

    initVectors({commit}){
      var vectorsDict = {};
      commit('vectors', vectorsDict)
      return true
    },

    async getVectorsByType({commit},{calcId, vectorType, index}){
      let cores = ['#DAD1CA', '#D9D1CA', '#66B7FF', '#66B7FF', '#605C63', '#F2D9D8', '#CDF7C9', '#66B7FF', '#735857', '#466347', '#61686C', '#E6E9DE', '#ECDCE9', '#E3CCDF', '#66B7FF'];
      try {
        var vectorsDict = store.getters.vectors;
        const res = await apiAxios.get('vectors/'+calcId+'/'+vectorType)
        if(res.status==500){
          window.localStorage.setItem('service_status','unstable')
        }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          var parseData = JSON.parse(res.data)
          var arrayData = [{'visible': true, 'geoms': parseData['features'], 'vector': parseData, 'type': vectorType, 'color': cores[index], 'strokeWidth': 3}]
          vectorsDict[vectorType] = arrayData;
          commit('vectors', vectorsDict)
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },

    async updateVectorLoop({commit},{calcId, payload, vectorType, validationStoragedProps}){
      try {
        let i;
          for(i=0;i<vectorType.length;i++){
            if(validationStoragedProps[vectorType[i]] === true){
              const res = await apiAxios.patch('vectors/'+calcId, payload[vectorType[i]])
              if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
              if(res.status==200){
                window.localStorage.setItem('service_status','stable')
                commit('updateVectorsByType',res.data)
                return true
              }
            }
        }
        return true
      } catch (error) {
        // alert(error)
        console.log(error)
        return false
      }
},

    async storageProps({commit},{vectorType, srid}){
      var propsDict = {};
      commit('storagedProps', propsDict)
      var validStoragedProps = {};
      commit('validationStoragedProps', validStoragedProps)
      try {
        let i;
        for(i=0;i<vectorType.length;i++){
          var data = {"list_geojson": [
            {"name": vectorType[i],
              "geojson": {
                  "type": "FeatureCollection",
                  "crs": {
                      "type": "name",
                      "properties": {
                          "name": "urn:ogc:def:crs:EPSG::"+srid
                      }
                  },
                  "features": []
                }
              }
            ]
          }
          propsDict[vectorType[i]] = data;
          commit('storagedProps', propsDict)
          validStoragedProps[vectorType[i]] = false;
          commit('validationStoragedProps', validStoragedProps)
        }
      } catch (error) {
        console.log(error)
        return false;
      }
      return true;
    },

    async updateStoragedProps({commit},{vectorType,obj}){
      var validStoragedProps = store.getters.validationStoragedProps;
      var updatePropsDict = store.getters.storagedProps;
      updatePropsDict[vectorType]['list_geojson'][0]['geojson']['features'].push(obj);
      commit('storagedProps', updatePropsDict)
      validStoragedProps[vectorType] = true;
      commit('validationStoragedProps', validStoragedProps)
    },

    async getBuffer({commit},calcId){
      try {
        const res = await apiAxios.get('layer_polygon/'+calcId)
        if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          res.data.forEach(item => {
            if(item.name=='buffer'){
              // console.log('limit',item)
              commit('buffer',item)
            }
          })
          return true
        }
        return res.statusText
      } catch (error) {
        // alert(error)
        console.log(error)
        return false
      }
    },

  }
}
