import {apiAxios} from '../helpers/interceptors'

export default {
  state: {
    allSteps: undefined,
    calcs: undefined,
    currentCalc: undefined,
    currentExpropriation: undefined,
    currentCurves: undefined,
    currentRailTypes: undefined,
    currentCapex: undefined,
    currentCapexDetails: undefined,
    currentBuilt: undefined,
    statuses: undefined,
    lineStringDataResp: undefined,
    paramsChanges: undefined,
    helper: undefined,
    new_alternative: undefined,
  },
  mutations: {
    allSteps: (state, value) => state.allSteps = value,
    lineStringDataResp: (state, value) => state.lineStringDataResp = value,
    calcs: (state, value) => state.calcs = value,
    currentCalc: (state, value) => state.currentCalc = value,
    currentExpropriation: (state, value) => state.currentExpropriation = value,
    currentCurves: (state, value) => state.currentCurves = value,
    currentRailTypes: (state, value) => state.currentRailTypes = value,
    currentCapex: (state, value) => state.currentCapex = value,
    currentCapexDetails: (state, value) => state.currentCapexDetails = value,
    currentBuilt: (state, value) => state.currentBuilt = value,
    addCalc (state, calc) {state.calcs[calc.id] = calc},
    updateCalc (state, calc) {state.calcs[calc.id] = calc},
    deleteCalc: (state, id) => state.calcs = state.calcs[id] = undefined,
    statuses: (state, value) => state.statuses = value,
    updateStatus (state, status) {state.statuses[status.calc] = status},
    paramsChanges: (state, value) => state.paramsChanges = value,
    reset: (state) => {
      Object.keys(state).forEach(key => {
        state[key]=undefined
      })
    },
    helper: (state, value) => state.helper = value,
    new_alternative: (state, value) => state.new_alternative = value,
  },
  getters: {
    allSteps: state => state.allSteps,
    lineStringDataResp: state => state.lineStringDataResp,
    steps: (state) => (type) => {return state.allSteps[type]},
    calcs: state => state.calcs,
    currentCalc: state => state.currentCalc,
    currentExpropriation: state => state.currentExpropriation,
    currentCurves: state => state.currentCurves,
    currentRailTypes: state => state.currentRailTypes,
    currentCapex: state => state.currentCapex,
    currentCapexDetails: state => state.currentCapexDetails,
    currentBuilt: state => state.currentBuilt,
    statuses: state => state.statuses,
    paramsChanges: state => state.paramsChanges,
    status: (state) => (id) => {
      try {
        if(id==undefined) {
          return {step: 1}
        } else {
          return state.statuses[id]
        }
      } catch(error) {
        console.log(error)
        return false
      }
    },
    helper: state => state.helper,
    new_alternative: state => state.new_alternative,
  },
  actions: {
    setSteps({commit}){
      commit('allSteps',{
        'railway': ['Calc creation','Adjust prices','Adjust parameters','Set mandatory coordinates','Limit characterization area','Calculate corridos','Calculate alternatives','Detail alternatives'],
        'roadway': ['Calc creation','Adjust prices','Adjust parameters','Set mandatory coordinates','Limit characterization area','Calculate corridos','Calculate alternatives','Detail alternatives'],
      })
      return true
    },
    async getCalcs({commit}) {
      try {
        const res = await apiAxios.get("calc?status=a")
        // console.log('resposta getCalcs ', res)
        if(res.status==500){
          window.localStorage.setItem('service_status','unstable')
        }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          let dict = {}
          res.data.forEach(item => {
            if(item.status=='a'){
              dict[item.id] = item
            }
          })
          commit('calcs', dict)
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    setHelper({commit},value){
      commit('helper', value)
    },
    async getCurrentCalc({commit},calcId) {
      try {
        const res = await apiAxios.get("calc/"+calcId)
        if(res.status==500){
          window.localStorage.setItem('service_status','unstable')
        }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          //Capex
          let capex = res.data.capex.CAPEX
          commit('currentCapexDetails', res.data.capex.Details)
          let capexRows = []
          capex['Level'].forEach((level,idx) => {
            let dict = {
              'Code': capex['Code'][idx],
              'Code Level': capex['Code Level'][idx],
              'Code Up': capex['Code Up'][idx],
              'Level': capex['Level'][idx],
              'Description': capex['Description'][idx],
              'Risk': Math.round(capex['Risk'][idx]*10000)/100,
              'Unit': capex['Unit'][idx],
              'Unit Cost': capex['Unit Cost'][idx],
              'enable': true
            }
            if(dict['Unit'] == '% of CAPEX' || dict['Unit'] == '% of group' || dict['Unit'] == '% do CAPEX' || dict['Unit'] == '% do grupo'){
              dict['Unit Cost'] = Math.round(dict['Unit Cost']*10000)/100
            }
            capexRows.push(dict)
          })
          let expropriation = {'Protect Class': res.data.raster_budget.categories[0].subcategories['Protect Class']}
          let expropriationRows = []
          Object.keys(expropriation).forEach((group,group_idx) => {
            let code = group_idx+1
            if(code<10){code='0'+code}
            let group_code = code
            let title = {
              'Code': group_code,
              'Level': 1,
              'Description': group,
              'Cost': undefined
            }
            expropriationRows.push(title)
            expropriation[group].names.forEach((name,name_idx) => {
              let code = name_idx+1
              if(code<10){code='0'+code}
              let dict = {
                'Code': group_code+'.'+code,
                'Level': 3,
                'Description': name,
                'Cost': expropriation[group].values.base[name_idx]
              }
              expropriationRows.push(dict)
            })
          })
          commit('currentExpropriation', expropriationRows)
          commit('currentCapex', capexRows)
          if(res.data.type == 'railway'){
            //PARAMS
            //rail_types
            let railTypes = res.data.values_params.rail_types
            railTypes.model
            railTypes.boleto_c
            let railTypesRows = []
            railTypes.model.forEach((model,idx) => {
              let dict = {
                'Id': idx,
                'Model': model,
                'Boleto': railTypes.boleto_c[idx],
              }
              railTypesRows.push(dict)
            })
            commit('currentRailTypes', railTypesRows)
          }

          //axis_geometry curves
          let curves = res.data.values_params.axis_geometry.list_curves
          let curveRows = []
          curves.forEach(item => {
            let dict = {
              'Radius': item[0],
              'Ls': item[1],
              'Penalty': item[2],
              'Superelevation': item[3],
              'Compensation': item[4],
            }
            curveRows.push(dict)
          })
          commit('currentCurves', curveRows)

          //params
          let params = res.data.values_params
          params.axis_geometry.max_grade = (params.axis_geometry.max_grade*100).toFixed(2)
          params.axis_geometry.min_grade = (-params.axis_geometry.min_grade*100).toFixed(2)
          params.platform.platform_slope = params.platform.platform_slope*100
          params.earthwork.cut_enbankment_slope = params.earthwork.cut_enbankment_slope*100
          params.earthwork.second_cat_rate = (params.earthwork.second_cat_rate*100).toFixed(2)
          params.earthwork.third_cat_rate = params.earthwork.third_cat_rate*100
          params.earthwork.fill_enbankment_slope = params.earthwork.fill_enbankment_slope*100

          if(res.data.type == 'roadway'){
            params['imgs'] = {
              "aquisition": "Road_and_lanes_1_lane",
              "bridge": "bridge_section_1_lane",
              "cut": "cut_1_lane",
              "fill": "fill_1_lane",
              "roads": "Road_and_lanes_1_lane",
              "tunnel": "tunnel_section_1_lane",
              "structure_total_width": 33,
              "structure_lanes_total_width": 3.5,
              "has_central_barrier": "No",
              "asphalt_depht" : 0.13,
              "road_table_selected": {
                  "line": 2,
                  "col": 2,
                  "name": null
              },
              "roadway_table": [
                ['Region',	'O',	'I',	'II',	'III', 'IV', ''],
                ['',	'',	'',	'',	'',	'A', 'B'],
                ['Plain field',	'540',	'375',	'375',	'230',	'230',	'125'],
                ['Irregular field',	'345',	'210',	'170',	'125',	'125',	'50'],
                ['Mountainous region',	'210',	'115',	'80',	'50',	'50',	'25']
              ],
            }
          }

          commit('currentCalc', res.data)
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async createCalc(context,payload) {
      try {
        const res = await apiAxios.post('calc', payload)
        if(res.status==500){
          window.localStorage.setItem('service_status','unstable')
        }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          context.commit('addCalc', res.data)
          context.dispatch('getStatuses')
          return {'id': res.data.id}
        }
        return res.statusText
      } catch (error) {
        alert(error)
        console.log(error)
        return false
      }
    },
    async resendCloud(context,payload){

      const res = await apiAxios.get('resend_detailed_files_to_cloud/'+payload.hash+'/'+payload.id)

      console.log('resp is ', res)
      
    },
    async updateCalc(context,payload) {
      try {
        const id = payload.id
        let txt = "calc/" + id
        const res = await apiAxios.patch(txt, payload)
        if(res.status==500){
          window.localStorage.setItem('service_status','unstable')
        }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          context.commit('updateCalc', res.data)
          return true
        }
        return res.statusText
      } catch (error) {
        alert(error)
        console.log(error)
        return false
      }
    },
    async updateRasterBudget() {
      try {
        let currentExpropriation = this.getters.currentExpropriation
        let expropriation = {'Protect Class': this.getters.currentCalc.raster_budget.categories[0].subcategories['Protect Class']}

        // Expropriation
        // let idx = 16
        let idx = 1
        Object.values(expropriation).forEach(group => {
          group.names.forEach((name,groupedIdx) => {
            group.values.base[groupedIdx] = parseFloat(currentExpropriation[idx].Cost)
            idx=idx+1
          })
          idx=idx+1
        })

        let payload = {deprecated: false, raster_budget: this.getters.currentCalc.raster_budget}


        const res = await apiAxios.patch("calc/" + this.getters.currentCalc.id, payload)
        if(res.status==500){
          window.localStorage.setItem('service_status','unstable')
        }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async updateCapex() {
      try {
        let capexCopy = JSON.parse(JSON.stringify(this.getters.currentCalc.capex));
        
        let unitCost = []
        let risk = []
        let descriptions = []
        let unit = []
        Object.values(this.getters.currentCapex).forEach(item => {
          if(item['Unit']=='% of CAPEX' || item['Unit']=='% of group' || item['Unit'] == '% do CAPEX' || item['Unit'] == '% do grupo'){
            unitCost.push(parseFloat(Math.round(item['Unit Cost']*100)/10000))
          }
          else{
            unitCost.push(parseFloat(item['Unit Cost']))
          }
          risk.push(parseFloat(Math.round(item.Risk*100)/10000))
          descriptions.push(item.Description)
          unit.push(item.Unit)
        })

        capexCopy.CAPEX['Unit Cost'] = unitCost
        capexCopy.CAPEX.Risk = risk
        capexCopy.CAPEX.Description = descriptions
        capexCopy.CAPEX.Unit = unit
        
        let payload = { deprecated: false, capex: capexCopy}

        const res = await apiAxios.patch("calc/"+this.getters.currentCalc.id, payload)
        if(res.status==500){
          window.localStorage.setItem('service_status','unstable')
        }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async updateParams() {
      try {
        let valuesParamsCopy = JSON.parse(JSON.stringify(this.getters.currentCalc.values_params));

        //curves
        let currentCurves = this.getters.currentCurves
        let curves = valuesParamsCopy.axis_geometry.list_curves
        Object.values(curves).forEach((item,idx) => {
          item[0] = currentCurves[idx].Radius*1
          item[1] = currentCurves[idx].Ls*1
          item[2] = currentCurves[idx].Penalty*1
          item[3] = currentCurves[idx].Superelevation/100
          item[4] = currentCurves[idx].Compensation*1
        })

        valuesParamsCopy.superelevation.security_coeficient = valuesParamsCopy.superelevation.security_coeficient * 1

        //grade
        valuesParamsCopy.axis_geometry.max_grade = valuesParamsCopy.axis_geometry.max_grade/100
        valuesParamsCopy.axis_geometry.min_grade = -valuesParamsCopy.axis_geometry.min_grade/100
        valuesParamsCopy.platform.platform_slope = valuesParamsCopy.platform.platform_slope/100
        valuesParamsCopy.earthwork.cut_enbankment_slope = valuesParamsCopy.earthwork.cut_enbankment_slope/100
        valuesParamsCopy.earthwork.second_cat_rate = valuesParamsCopy.earthwork.second_cat_rate/100
        valuesParamsCopy.earthwork.third_cat_rate = valuesParamsCopy.earthwork.third_cat_rate/100
        valuesParamsCopy.earthwork.fill_enbankment_slope = valuesParamsCopy.earthwork.fill_enbankment_slope/100

        //others
        let payload = { deprecated: false, values_params: valuesParamsCopy}

        const res = await apiAxios.patch("calc/"+this.getters.currentCalc.id, payload)
        if(res.status==500){
          window.localStorage.setItem('service_status','unstable')
        }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async getStatuses({commit}){
      try {
        const res = await apiAxios.get('status')
        if(res.status==500){
          window.localStorage.setItem('service_status','unstable')
        }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          let dict = {}
          let stats = res.data
          stats = Object.values(stats).sort((p1,p2) => (p1.updated_at < p2.updated_at) ? 1 : (p1.updated_at > p2.updated_at) ? -1 : 0)
          let timeNow = Date.now()
          stats.forEach(item => {
            let time = Date.parse(item.updated_at)
            let delta = (timeNow-time)/1000
            if(delta<60){item.updatedAtFormatted = Math.round(delta).toString()+' seconds ago'}
            else if(delta<3600){item.updatedAtFormatted = Math.round(delta/60).toString()+' minutes ago'}
            else if(delta<(3600*24)){item.updatedAtFormatted = Math.round(delta/3600).toString()+' hours ago'}
            else if(delta<(3600*24*30)){item.updatedAtFormatted = Math.round(delta/3600/24).toString()+' days ago'}
            else if(delta<(3600*24*365)){item.updatedAtFormatted = Math.round(delta/3600/24/30).toString()+' months ago'}
            else item.updatedAtFormatted = Math.round(delta/3600/24/365).toString()+' years ago'
            dict[item.calc] = item
          })
          commit('statuses', dict)
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async updateStatus({commit},id){
      try {
        const res = await apiAxios.get('status/'+id)
        if(res.status==500){
          window.localStorage.setItem('service_status','unstable')
        }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          commit('updateStatus',res.data)
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async changeStatus({commit},{calcId,payload}){
      try {
        const res = await apiAxios.post('status/'+calcId,payload)
        if(res.status==500){
          window.localStorage.setItem('service_status','unstable')
        }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          commit('updateStatus',res.data)
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },

    async saveLineStringFunc({commit},{calcId,payload}){
      try {
        const res = await apiAxios.post('layer_corridor_and_alternative/'+calcId,payload)
        if(res.status==500){
          window.localStorage.setItem('service_status','unstable')
        }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          commit('lineStringDataResp',res.data)
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },

    async newAlternativeRoute({commit}, {calcId,alternativeNumber}){
      if(!this.getters.paymentId){
        return "You haven't registered a payment method!"
      } else{
        const res = await apiAxios.get('new_alternatives/'+this.getters.paymentId+'/'+calcId+'/'+alternativeNumber)
        // console.log('resp ', res)
        if(res.status==500){
          window.localStorage.setItem('service_status','unstable')
        }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          commit('new_alternative', res)
          return true
        } else{
          console.log('error is', res)
          return false
        }
      }
    },
  }
}
