import Vue from "vue"
import Router from "vue-router"
import homeRoutes from './home.js'
import calcRoutes from './calc.js'

Vue.use(Router)

var routes = homeRoutes
Array.prototype.push.apply(routes,calcRoutes)

// console.log('routes',routes)

export default new Router({
  mode: 'history',
  hash: false,
  routes
})