import {apiAxios} from '../helpers/interceptors'
import store from '../store/index'

export default {
  state: {
    // reports: undefined,
  },
  mutations: {
    reset: (state) => {
      Object.keys(state).forEach(key => {
        // console.log('reset ',key,': ',state[key])
        state[key]=undefined
      })
    }
  },
  getters: {
    // reports: state => state.reports,
  },
  actions: {
    async corridorCalc(context,calcId) {
      await apiAxios.post('corridor_calc/'+calcId,{'password': store.getters.cloudPassword})
      return true
    },
    async basicCalc(context,calcId) {
      await apiAxios.post('basic_calc/'+calcId,{'password': store.getters.cloudPassword})
      return true
    },
    async detailedCalc(context,{calcId,alternativeNumber}){
      try {// if alternativeNumber==0 calculate all alternatives
        // console.log('payload',calcId,alternativeNumber)
        const res = await apiAxios.post('detailed_calc/'+calcId+'/'+alternativeNumber,{'password': store.getters.cloudPassword})
        if(res.status==500){
          window.localStorage.setItem('service_status','unstable')
        }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          return true
        }
        return false
      } catch (error) {
        alert(error)
        console.log(error)
        return false
      }
    },
  }
}
