import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth.js'
import monetize from './monetize.js'
import config from './config.js'
import calc from './calc.js'
import vector from './vector.js'
import alignment from './alignment.js'
import external from './external.js'
import hidrology from './hidrology.js'

import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        auth,
        monetize,
        config,
        calc,
        vector,
        alignment,
        external,
        hidrology
    },
    plugins: [createPersistedState()]
})

export default store
