export var english =
    {
        "profile_form_name_1":"First Name",
        "profile_form_name_2":"Last Name (Optional)",
        "profile_form_email":"Email",
        "profile_form_password":"Password",
        "profile_form_phone":"Telefone (Opcional)",
        "profile_form_country":"Select your country (Opcional)",
        "profile_form_civil_id":"Civil Id (Opcional)",
        "profile_form_work_id":"Work Id (Opcional)",
        "profile_form_language":"Select default language",
        "profile_form_btn_save":"SAVE CHANGES",
        "navigation_title_name_1":"Railway",
        "navigation_title_name_2":"Roadway",
        "navigation_title_name_3":"hidrology",
        "navigation_title_name_4":"Power Line",
        "navigation_title_name_5":"Duct",
        "navigation_title_name_6":"Dam",
        "navigation_title_name_7":"Riparian Forest",
        "navigation_title_name_8":"Allotment",
        "newrailway_title":"Railway project",
        "newrailway_description":"Railway project description",
        "newRoadway_title":"Roadway project",
        "newRoadway_description":"Roadway project description",
        "newrailway_change_params":"Change prices and parameters",
        "newrailway_change_params_close":"Close",
        "newrailway_card_title":"Create new calculation",
        "newrailway_card_title_textfield_title":"Title",
        "newrailway_card_description_textfield_title":"Description (optional)",
        "newrailway_card_owner_textfield_title":"Owner (optional)",
        "newrailway_card_params_textfield_title":"Select prices and parameters already created",
        "newrailway_card_btn_crete_title":"Create calc",
        "btn_cancel_title":"Cancel",
        "projectCreate_userParams_railway_change_card_tittle":"Change prices and parameters",
        "projectCreate_userParams_railway_change_card_btn_newItem_tittle":"New Item",
        "projectCreate_userParams_railway_change_card_select_lable":"Create from wwcalc default:",
        "projectCreate_userParams_railway_change_header":[
            { text: 'Name', align: 'start', sortable: true, value: 'name',},
            { text: 'Currency', align: 'start', value: 'currency',},
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        "projectCreate_userParams_railway_change_cardNew_projTittle_tittle":"Customized configuration title",
        "projectCreate_userParams_railway_change_cardNew_curency_tittle":"Currency",
        "projectCreate_userParams_railway_change_cardNew_currencyRef_tittle":"Exchange (from USD)",
        "projectCreate_userParams_railway_change_cardNew_btn_name2":"Create customized configuration",
        "projectCreate_userParams_railway_change_cardCopy_tittle":"Copy item",
        "projectCreate_userParams_railway_change_cardCopy_field_lable":"Copy name",
        "projectCreate_userParams_railway_change_cardCopy_delete_field_lable":"Are you sure you want to delete this item?",
        "termsOfService_tittle": "Terms of use",
        "privacyPolicy_tittle": "Privacy Policy",
        "update_banner_text": "Update for better calcs",
        "support_component_text": "WWCALC processes railway, road, and hydrological data, with a robust and scalable infrastructure. Soon, it will also process studies of transmission lines, dams, subdivisions, houses, buildings, solar farms, and much more. Our algorithms aim for the best socio-economic, market, CAPEX, and OPEX results, as well as environmental alternatives.",
        "support_component_text2": "Comercial support",
        "support_component_text3": "TECHNICAL SUPPORT",
        "support_component_text4": "RAILWAY HOW TO",
        "support_component_text5": "Learn about WWCALC",
        "support_component_text5_video_2": "Contact, support, register and credits purchase",
        "support_component_text5_video_3": "Railway and Highway Parameters",
        "support_component_text5_video_4": "Maps and coordinates",
        "support_component_text5_video_5": "Documents on Cloud",
        "support_component_text5_video_6": "Comparison of alternatives",
        "support_component_text5_video_7": "Plant and Profile",
        "support_component_text5_video_8": "Vectors on QGIS",
        "support_component_text5_video_9": "Plugin on QGIS",
        "support_component": "https://www.youtube.com/watch?v=URMjNfsj5nA&t=2s",
        "support_component_video_2": "https://www.youtube.com/watch?v=4Mqv2eDrQqk",
        "support_component_video_3": "https://www.youtube.com/watch?v=tSzjkFb3UIg",
        "support_component_video_4": "https://www.youtube.com/watch?v=9hxdag25uBw",
        "support_component_video_5": "https://www.youtube.com/watch?v=pBwysRxm-xg",
        "support_component_video_6": "https://www.youtube.com/watch?v=gmI95d8SD_Y",
        "support_component_video_7": "https://www.youtube.com/watch?v=cKyhXhhQe3c",
        "support_component_video_8": "https://www.youtube.com/watch?v=siiWk62D_RY",
        "support_component_video_9": "https://www.youtube.com/watch?v=t1plPsr5Sbk",
        "register_component_text1": "Email",
        "register_component_text2": "Enter your best email",
        "register_component_text3": "Password",
        "register_component_text4": "Enter a hard password",
        "register_component_text5": "Confirm password",
        "register_component_text6": "Enter a hard password",
        "register_component_text7": "First name",
        "register_component_text8": "Your first name",
        "register_component_text9": "Last name (optional)",
        "register_component_text10": "Your last name",
        "register_component_text11": "Phone (optional)",
        "register_component_text12": "Your phone number",
        "register_component_text13": "Civil id (optional)",
        "register_component_text14": "Your civil id",
        "register_component_text15": "Work id (optional)",
        "register_component_text16": "Your work id",
        "register_component_text17": "Click here to read the terms and register",
        "register_component_text18": "Hide terms",
        "register_component_text19": "I have read, understood and agree to the Terms of Use",
        "register_component_text20": "I have read, understood and agree to the Privacy Policy",
        "register_component_text21": "Register",
        "register_component_text22": "You will receive an email for validation.",
        "register_component_text23": "Remember to check your spam folder.",
        "register_component_text24": "After validation you will be able to use the application.",
        "register_component_text25": "I'm already registered. Go to login.",
        "pricing_component_text1": "One WWCALC credit",
        "pricing_component_text2": "costs today",
        "pricing_component_text3": "The price of credits follows the exchange rate variation from Reais to Dollars. We recommend purchasing in advance.",
        "pricing_component_text4": "Calculation costs",
        "pricing_component_text5": "Value",
        "pricing_component_text6": "Unit",
        "passwordReset_component_text1": "Change",
        "passwordReset_component_text2": "password to",
        "passwordReset_component_text3": "WWCALC application.",
        "passwordReset_component_text4": "Password",
        "passwordReset_component_text5": "Enter a hard password",
        "passwordReset_component_text6": "Confirm password",
        "passwordReset_component_text7": "Enter a hard password",
        "passwordReset_component_text8": "Confirm password change",
        "passwordReset_component_text9": "Password changed successfully. Wait while you are being logged in.",
        "home_component_text1": "Connecting to your cloud",
        "home_component_text2": "Enter your cloud password to continue",
        "home_component_text3": "Enter your cloud password",
        "home_component_text4": "Connect",
        "home_component_text5": "Loading your data",
        "home_component_text6": "You have no calculation. Try some on the left menu.",
        "cardForm_component_text1": "Number",
        "cardForm_component_text2": "Enter your credit card number",
        "cardForm_component_text3": "Full name",
        "cardForm_component_text4": "name on credit card",
        "cardForm_component_text5": "Expiry date",
        "cardForm_component_text6": "CVV",
        "cardForm_component_text7": "CVV on the back of your credit card",
        "cardForm_component_text8": "Cancel",
        "cardForm_component_text9": "Save",
        "billingMercadoPago_component_text1": "BUY CREDITS",
        "billingMercadoPago_component_text2": "Update credit card",
        "billingMercadoPago_component_text3": "Buy",
        "billingMercadoPago_component_text4": "CREDITS TRANSACTIONS",
        "billingMercadoPago_component_text5": "BUY",
        "billingMercadoPago_component_text6": "Export",
        "billingMercadoPago_component_text7": "Buy credits?",
        "billingMercadoPago_component_text8": "Confirm the purchase of",
        "billingMercadoPago_component_text9": "for",
        "billingMercadoPago_component_text10": "Not now",
        "billingMercadoPago_component_text11": "Confirm",
        "updateDefaultParams_component_text1": "Restriction",
        "updateDefaultParams_component_text2": "Grade & Curves",
        "updateDefaultParams_component_text3": "Platform",
        "updateDefaultParams_component_text4": "Earthwork",
        "updateDefaultParams_component_text5": "Save",
        "updateDefaultParams_component_text6": "Checking version data, please wait a moment.",
        "roadway_component_text1": "Next",
        "roadway_component_text2": "Internal Error",
        "roadway_component_text3": "Unfortunately it has occurred an error during processing, for that reason your credits will be refund and this project archived",
        "roadway_component_text4": "Delete",
        "roadway_component_text5": "Title",
        "roadway_component_text6": "Description (optional)",
        "roadway_component_text7": "Owner (optional)",
        "roadway_component_text8": "If you choose to continue, up to 10 alternatives leaving the starting point and arriving at the end point will be suggested.",
        "roadway_component_text9": "You will use",
        "roadway_component_text10": "credits to go to the next step of the calculation.",
        "roadway_component_text11": "Calculations are carried out considering the limit area, budget and the chosen parameters. If you want to redo the calculation later, changing any parameter, you need to create a new calc.",
        "roadway_component_text12": "Finding data for the chosen area",
        "roadway_component_text13": "Finding good paths",
        "roadway_component_text14": "Corridors calculated successfully! Go to next step.",
        "roadway_component_text15": "Something went wrong with the calculation.",
        "roadway_component_text16": "Your credits will be returned and the technical team will investigate the problem so that it does not happen again.",
        "roadway_component_text17": "Finding possible geometries",
        "roadway_component_text18": "Comparing alternatives found",
        "roadway_component_text19": "Alternatives calculated successfully! Go to next step to compare them.",
        "roadway_component_text20": "Use credits?",
        "roadway_component_text21": "Confirm use of",
        "roadway_component_text22": "Not now",
        "roadway_component_text23": "Confirm",
        "roadway_component_text24": "Corridor ",
        "roadway_component_text25": "Alternative ",
        "map_component_text1": "Back",
        "map_component_text2": "Layer",
        "map_component_text3": "BASE LAYERS",
        "map_component_text4": "Import",
        "map_component_text5": "Add points",
        "map_component_text6": "Geometry SRID",
        "map_component_text7": "Coordinate Format",
        "map_component_text8": "Geometry in GeoJson format",
        "map_component_text9": "Geometry in Wkt format",
        "map_component_text10": "Cancel",
        "map_component_text11": "Add",
        "map_component_text12": "Add polylines",
        "map_component_text13": "Geometry in EWKT format",
        "map_component_text14": "Add polygons",
        "map_component_text15": "Search",
        "map_component_text16": "Place name",
        "map_component_text17": "Erase",
        "map_component_text18": "Next",
        "map_component_text19": "OBJECT IS BEEING IGNORED",
        "map_component_text20": "Ignore vector",
        "map_component_text21": "Done",
        "map_component_text21_2": "Exit",
        "map_component_text22": "Curves list",
        "map_component_text23": "Save",
        "detail_component_text1": "Map",
        "detail_component_text2": "Comparative",
        "detail_component_text3": "Detail Alternative",
        "detail_component_text4": "Layer",
        "detail_component_text5": "BASE LAYERS",
        "detail_component_text6": "Please wait while vectors are being loaded...",
        "detail_component_text7": "Loading Alternatives Report",
        "detail_component_text8": "Rendering calcs",
        "detail_component_text9": "SELECT ALTERNATIVES",
        "detail_component_text10": "Use credits?",
        "detail_component_text11": "Enter your cloud password to continue",
        "detail_component_text12": "Not now",
        "detail_component_text13": "Confirm",
        "detail_component_text14": "/_/report_alternative/Comparative.pdf",
        "createNewRoadway_component_text1": "Close",
        "createNewRoadway_component_text2": "Create new calculation",
        "createNewRoadway_component_text3": "Title",
        "createNewRoadway_component_text4": "Description (optional)",
        "createNewRoadway_component_text5": "Owner (optional)",
        "createNewRoadway_component_text6": "Select prices and parameters already created",
        "createNewRoadway_component_text7": "Create calc",
        "createNewRoadway_component_text8": "Cancel",
        "costMapUpdate_component_text1": "Restrictions",
        "costMapUpdate_component_text2": "Cancel",
        "costMapUpdate_component_text3": "Done",
        "costMapUpdate_component_text4": "Map layers",
        "costMapUpdate_component_text5": "BASE LAYERS",
        "costMapUpdate_component_text6": "Buffer and vectors",
        "costMapUpdate_component_text7": "Vectors",
        "costMapUpdate_component_text8": "Buffer",
        "costMapUpdate_component_text9": "Limit",
        "costMapUpdate_component_point": "Track",
        "costMapUpdate_component_text10": "Recalculate",
        "costMapUpdate_component_text11": "Next",
        "costMapUpdate_component_text12": "Please wait while vectors are being loaded...",
        "costMapUpdate_component_text13": "Use credits?",
        "costMapUpdate_component_text14": "Confirm use of",
        "costMapUpdate_component_text15": "Enter your cloud password to continue",
        "costMapUpdate_component_text16": "Enter your cloud password",
        "costMapUpdate_component_text17": "Not now",
        "costMapUpdate_component_text18": "Confirm",
        "adjustPricesDefault_component_text1": "Restriction",
        "adjustPricesDefault_component_text2": "Grade & Curves",
        "adjustPricesDefault_component_text3": "Platform",
        "adjustPricesDefault_component_text4": "Earthwork",
        "adjustPricesDefault_component_text5": "Save",
        "adjustPricesDefault_component_text5_2": "Cancel",
        "adjustPricesDefault_component_text6": ['Yes', 'No'],
        "adjustPricesDefault_component_text7": "Has Central barrier?",
        "adjustPricesDefault_component_text8": "Number of lanes",
        "adjustPricesDefault_component_text9": "Central barrier",
        "adjustPricesDefault_component_text10": ['None', 'New Jersey', 'Metal Defense'],
        "adjustPricesDefault_component_text11": "Side barrier",
        "adjustPricesDefault_component_text12": "Yes",
        "adjustPricesDefault_component_text13": "No",
        "adjustPricesDefault_component_text14": "None",
        "adjustPricesDefault_component_text15": "New Jersey",
        "adjustPricesDefault_component_text16": "Metal Defense",
        "adjustPrices_component_text1": "Restriction",
        "adjustPrices_component_text2": "Next",
        "adjustPrices_component_text3": "Back",
        "adjustPrices_component_text4": "Edit",
        "adjustParameters_component_text1": "Back",
        "adjustParameters_component_text2": "Grade & Curves",
        "adjustParameters_component_text3": "Platform",
        "adjustParameters_component_text4": "Earthwork",
        "adjustParameters_component_text5": ['Yes', 'No'],
        "adjustParameters_component_text6": "Has Central barrier?",
        "adjustParameters_component_text7": ['None'],
        "adjustParameters_component_text8": "Central barrier",
        "adjustParameters_component_text9": ['None', 'New Jersey', 'Metal Defense'],
        "adjustParameters_component_text10": "Central barrier",
        "adjustParameters_component_text11": ['None', 'New Jersey', 'Metal Defense'],
        "adjustParameters_component_text12": "Side barrier",
        "adjustParameters_component_text14": "No",
        "adjustParameters_component_text15": "Yes",
        "adjustParameters_component_text16": "None",
        "adjustParameters_component_text17": "New Jersey",
        "adjustParameters_component_text18": "Metal Defense",
        "curves_component_text1": "Grade",
        "curves_component_text2": "Main way max uphill (%)",
        "curves_component_text3": "Opposite way max uphill (%)",
        "curves_component_text4": "Vertical curve length (m)",
        "curves_component_text5": "Superelevation",
        "curves_component_text6": "Operational speed",
        "curves_component_text7": "Select speed",
        "curves_component_text8": "Choose Minimum Radius (m)",
        "curves_component_text9": "Region",
        "curves_component_text10": "Roadway Classes",
        "curves_component_text11": "HORIZONTAL CURVES",
        "curves_component_text12": "New Item",
        "curves_component_text13": "Radius (m)",
        "curves_component_text14": "Spiral (m)",
        "curves_component_text15": "Penalty",
        "curves_component_text16": "Editar",
        "curves_component_text17": "Subtitle:",
        "curves_component_text18": "Ts - Teorichal superelevation",
        "curves_component_text19": "Vg - Vehicle center gravity",
        "curves_component_text20": "W - Weight force",
        "curves_component_text21": "Cf - Centrifugal force",
        "curves_component_text22": "Gauge width:",
        "curves_component_text23": "Rails model type",
        "curves_component_text24": "Operational speed",
        "curves_component_text25": "Security coeficient",
        "curves_component_text26": "Speed coeficient",
        "curves_component_text27": "Minimum radius",
        "earthworkCut1_component_text1": "Cut",
        "earthworkCut1_component_text2": "None",
        "earthworkCut1_component_text3": "New Jersey",
        "earthworkCut1_component_text4": "Metal Defense",
        "earthworkCut1_component_text5": "Height",
        "earthworkCut1_component_text6": "Cut Slope",
        "earthworkCut1_component_text7": "Height / Width",
        "earthworkCut1_component_text8": "Slope",
        "earthworkCut1_component_text9": "Width",
        "earthworkCut1_component_text10": "Cut 2ª category:",
        "earthworkCut1_component_text11": "% of Cut 1ª category",
        "earthworkCut1_component_text12": "Cut 3ª category:",
        "earthWorkFill1_component_text1": "Landfill",
        "earthWorkFill1_component_text2": "None",
        "earthWorkFill1_component_text3": "New Jersey",
        "earthWorkFill1_component_text4": "Metal Defense",
        "earthWorkFill1_component_text5": "Height",
        "earthWorkFill1_component_text6": "Landfill Slope",
        "earthWorkFill1_component_text7": "Height / Width",
        "earthWorkFill1_component_text8": "Enbankment Slope",
        "earthWorkFill1_component_text9": "Enbankment Width",
        "earthWorkTransport_component_text1": "Transport",
        "earthWorkTransport_component_text2": "transport",
        "earthWorkTransport_component_text3": "Cleaning height",
        "earthWorkTransport_component_text4": "Perpolation factor",
        "earthWorkTransport_component_text5": "Shrinkage Factor",
        "earthWorkTransport_component_text6": "Loan Mean Distance",
        "earthWorkTransport_component_text7": "Bootout Mean Distance",
        "platform1Lane_component_text1": "Platform Superstructure",
        "platform1Lane_component_text2": "None",
        "platform1Lane_component_text3": "New Jersey",
        "platform1Lane_component_text4": "Metal Defense",
        "platform1Lane_component_text5": "Shoulder Width",
        "platform1Lane_component_text6": "Asphalt density",
        "platform1Lane_component_text7": "Lane width",
        "platform1Lane_component_text8": "First asphalt depth",
        "platform1Lane_component_text9": "Second asphalt depth",
        "platform1Lane_component_text10": "Base height",
        "platform1Lane_component_text11": "Sub-base height",
        "platform1Lane_component_text12": "Median width",
        "platformAquisition1Lane_component_text1": "Aquisition",
        "platformAquisition1Lane_component_text2": "None",
        "platformAquisition1Lane_component_text3": "New Jersey",
        "platformAquisition1Lane_component_text4": "Metal Defense",
        "platformAquisition1Lane_component_text5": "Aquisition width",
        "platformAquisition1Lane_component_text6": "Medium earthwork width",
        "platformAquisition1Lane_component_text7": "Free margin",
        "platformBridge_component_text1": "Bridge",
        "platformBridge_component_text2": "bridge length",
        "platformBridge_component_text3": "Max fill height",
        "platformBridge_component_text4": "Bridge margin",
        "platformBridge_component_text5": "Max bridge height",
        "platformBridge1Lane_component_text1": "None",
        "platformBridge1Lane_component_text2": "New Jersey",
        "platformBridge1Lane_component_text3": "Metal Defense",
        "platformBridge1Lane_component_text4": "1 Lane Bridge width",
        "platformBridge1Lane_component_text5": "2 Lanes Bridge width",
        "platformTunnel_component_text1": "Tunnel",
        "platformTunnel_component_text2": "tunnel length",
        "platformTunnel_component_text3": "Max cut height",
        "platformTunnel_component_text4": "Tunnel margin",
        "platformTunnel1Lane_component_text1": "None",
        "platformTunnel1Lane_component_text2": "New Jersey",
        "platformTunnel1Lane_component_text3": "Metal Defense",
        "platformTunnel1Lane_component_text4": "1 Lane Tunnel width",
        "platformTunnel1Lane_component_text5": "2 Lanes Tunnel width",
        "platformSleepers_component_text1": "Sleepers",
        "platformSleepers_component_text2": "Depth",
        "platformSleepers_component_text3": "Width",
        "platformSleepers_component_text4": "Lenght",
        "platformSleepers_component_text5": "Spacing",
        "platform1Lane_railway_component_text1": "Platform Superstructure",
        "platform1Lane_railway_component_text2": "platform_1lane",
        "platform1Lane_railway_component_text3": "Margin spare",
        "platform1Lane_railway_component_text4": "Ballast Width",
        "platform1Lane_railway_component_text5": "Slope",
        "platform1Lane_railway_component_text6": "Rails density",
        "platform1Lane_railway_component_text7": "Ballast Density",
        "platform1Lane_railway_component_text8": "Ballast Height",
        "platform1Lane_railway_component_text9": "Ballast Slope",
        "platform1Lane_railway_component_text10": "Height / Width",
        "platform1Lane_railway_component_text11": "Suballast Height",
        "platform1Lane_railway_component_text12": "Comercial transport paved road distance",
        "platform1Lane_railway_component_text13": "Comercial transport primary paved road distance",
        "platform1Lane_railway_component_text14": "Comercial transport natural road distance",
        "platform1Lane_railway_component_text15": "Central Granty",
        "login_component_text1": "Enter your password",
        "login_component_text2": "Sing in",
        "login_component_text3": "Register",
        "login_component_text4": "Reset password",
        "login_component_text5": "You will receive an email for validation.",
        "login_component_text6": "Remember to check your spam folder.",
        "login_component_text7": "Some error occured. If this problem persists, contact support team.",
        "login_component_text8": "Tutorials Playlist",
        "allSteps_array_obj": 
            {
                "railway": [
                    "Calc creation",
                    "Adjust prices",
                    "Adjust parameters",
                    "Set mandatory coordinates",
                    "Limit characterization area",
                    "Calculate corridos",
                    "Calculate alternatives",
                    "Detail alternatives"
                ],
                "roadway": [
                    "Calc creation",
                    "Adjust prices",
                    "Adjust parameters",
                    "Set mandatory coordinates",
                    "Limit characterization area",
                    "Calculate corridos",
                    "Calculate alternatives",
                    "Detail alternatives"
                ]
            },
        "curvesTable_roadway_text1": [
            ['Region',	'O',	'I',	'II',	'III', 'IV', ''],
            ['',	'',	'',	'',	'',	'A', 'B'],
            ['Plain field',	'540',	'375',	'375',	'230',	'230',	'125'],
            ['Irregular field',	'345',	'210',	'170',	'125',	'125',	'50'],
            ['Mountainous region',	'210',	'115',	'80',	'50',	'50',	'25']
                ],
        "curvesTable_roadway_text2": [
            {name: 'Plain field', zero: 540, I: 375, II: 375, III: 230, A: 230, B: 125},
            {name: 'Irregular field', zero: 345, I: 210, II: 170, III: 125, A: 125, B: 50},
            {name: 'Mountainous region', zero: 210, I: 115, II: 80, III: 50, A: 50, B: 25}
            ],
        "curves_comp_list_arr_of_obj_1": [
            { text: "Radius (m)", value: "Radius" },
            { text: "Spiral (m)", value: "Ls" },
            { text: "Penalty (USD/m)", value: "Penalty", },
            { text: "Superelevation (%)", value: "Superelevation" },
            { text: "Actions", value: "actions", sortable: false },
        ],
        "curves_comp_list_arr_of_obj_2": [
            { text: 'Code', value: 'Code', sortable: false},
            { text: 'Description', value: 'Description', sortable: false},
            { text: 'Cost', value: 'Cost', sortable: false},
        ],
        "curves_comp_list_arr_of_obj_3": [
            { text: 'Code', value: 'Code', sortable: false, width: '6%'},
            { text: 'Description', value: 'Description', sortable: false},
            { text: 'Unit', value: 'Unit', sortable: false, width: '12%'},
            { text: 'Unit Cost', value: 'Unit Cost', sortable: false},
            { text: 'Risk (%)', value: 'Risk', sortable: false, width: '9%'},
        ],
        "railway_params_lable_1": "Number of lanes",
        "railway_params_lable_2": "Select gauge",
        "railway_params_lable_3": "Select rail type",
        "railway_params_lable_4": "Select speed",
        "rigths_1": "All rights reserved",
        "link_cloud_text_1":"Alternative",
        "terms_pdf":"Termo_en_v2024-03-01",
        "poli_pdf":"Politica_en_v2024-03-01",
        "terms_redirect":"terms-of-use",
        "poli_redirect":"privacy-policy",
        "help_text_1": "",
        "help_text_2": "Calculation Parameters",
        "help_text_3": "In addition to title, description, and owner, it is necessary to define the set of parameters for the calculation. WWCALC provides some standards as a basis for you to create your own. Create a standard, define the dollar conversion rate to the chosen currency. You can change the name, copy, delete, or edit your standard. The parameters are divided into groups: CAPEX, Constraints, Grade and Curves, Platform, and Earthworks. Changes will only be saved to the standard after clicking the Save button on the right side of the top menu. Save as many times as necessary.",
        "help_text_4": "In addition to title, description, and owner, it is necessary to define the set of parameters for the calculation. WWCALC provides some standards as a basis for you to create your own.",
        "help_text_5": "",
        "help_text_6": "",
        "help_text_7": "Capex",
        "help_text_8": "Capex is a list of parameters that determine the data to be taken into account when performing calculations to generate alternatives. Before calculating, you can go back and continue changing the parameters.",
        "help_text_15": "Choose the superstructure as needed. Items 12.02, 12.03, 12.06, 12.07 are adjustable.",
        "help_text_9": "Constraints",
        "help_text_10": "In the constraint parameters, you determine the value that will be considered when passing through protected areas, and generally the default values are much higher compared to others. Therefore, if the chosen area is likely to pass near a terrain with this characteristic, we recommend changing these values to generate better alternatives.",
        "help_text_11": "Define the dollar conversion rate to the chosen currency",
        "help_text_12": "Grade and curves",
        "help_text_13": "In the Grade and Curves group, define the maximum gradients. The gradient in the main direction starts from the initial point and goes towards the final point. The maximum gradient in the opposite direction is from the final point to the initial one. The longer the length of the vertical curve, the fewer occurrences in the alternatives. Choose between metric, mixed, and wide gauge. Select the rail model. Each model has its predefined density, lower and upper width, and height. Define the operational speed and safety coefficient. Set the speed coefficient for calculating superelevation. Define the minimum radius and all curves you want to use in the horizontal geometry of the railway. Enter spiral lengths or zero if it's a circular curve without transition. Impose penalties on curves with smaller radii so that they are used only when necessary. The superelevation for each curve is calculated automatically. Include, exclude, and edit lines in the curve table as needed.",
        "help_text_14": "Create a template. You can change the name, copy, delete, or edit your template.",
        "help_text_1_tooltip": "The gradient in the main direction starts from the initial point and goes towards the final point.",
        "help_text_2_tooltip": "The maximum gradient in the opposite direction is from the final point to the initial one.",
        "help_text_3_tooltip": "The longer the length of the vertical curve, the fewer occurrences in the alternatives.",
        "help_text_4_tooltip": "Define the speed coefficient for calculating superelevation.",
        "help_text_5_tooltip": "Define the minimum radius and all curves you want to use in the horizontal geometry of the railway.",
        "help_text_6_tooltip": "Enter spiral lengths or zero if it's a circular curve without transition. Impose penalties on curves with smaller radii so that they are used only when necessary. The superelevation for each curve is calculated automatically. Include, exclude, and edit lines in the curve table as needed.",
        "billing_data_obj_1": [
            { text: 'Date', value: 'date'},
            { text: 'Description', value: 'product.description'},
            { text: 'Status', value: 'status'},
            { text: 'Type - Tittle', value: 'calc_type'},
            { text: 'Value', value: 'value'},
            { text: 'Balance', value: 'balance_new'},
            { text: 'Actions', value: 'actions'},
        ],
        "billing_data_obj_2": [
            { text: 'Credits', value: 'qtty'},
            { text: 'Price ($)', value: 'price'},
            { text: 'Action', value: 'actions'},
        ],
        "billing_text_data_1": "Search",
        "billing_text_data_2": "Rows per page:",
        "detail_text_dialog_1":"Confirm use of",
        "detail_text_dialog_2":"Not now",
        "detail_text_dialog_3":"Confirm",
        "help_text_home_1": "Archive calc",
        "help_text_home_2": "Contact support",
        "status_text_1": "Starting corridor calcs",
        "status_text_2": "Finishing corridor calcs",
        "status_text_3": "Starting alternatives calcs",
        "status_text_4": "Finishing alternatives calcs",
        "Detail_table_header_text": [
            { text: 'Name', value: 'name'},
            { text: 'Estimated Price (', value: 'priceFormatted'},
            { text: 'Length', value: 'lengthFormatted'},
            { text: 'Status', value: 'actions', align: 'center', sortable: false,},
            { text: 'Actions', value: 'optimization', align: 'center', sortable: false,},
        ],
        "Select_capex_dialog_texts_1":"Description",
        "Select_capex_dialog_texts_2":"Unit",
        "Select_capex_dialog_texts_3":"Cost",
        "Select_capex_dialog_texts_4":"Cancel",
        "Select_capex_dialog_texts_5":"Save",
        "error_msg_txt_1":"At the moment, it was not possible to fulfill your request. Please try again later.",
        "error_msg_txt_2":"Services unstable at the moment calcs may take a little longer than usual to finish.",
        "text_home_1":"Step ",
        "text_home_2":"Updated ",
        "error_login_credentials_txt_1": "Invalid username or password",
        "error_login_credentials_txt_2": "System temporarily down. Please try again later.",
        "map_alert_text_1":"Not identified problem saving your mandatory points.",
        "map_alert_text_2":"Please, draw or upload a polygon, in order to determinate the area it will be calculated.",
        "map_alert_text_3":"Not identified problem saving your limit area.",
        "map_alert_text_4":"Please, enter two mandatory points (init and end of your alignments).",
        "map_alert_text_5":"The minimum distance between the points must be at least 500m.",
        "opitimization_text_1":"Optimize",
        "arealimit1kkm2_warning":"The selected limit area is larger than 1000 km². You will be notified by email when this calculation step is complete. Area size ",
        "register_rules_txt_1":"Field is required",
        "register_rules_txt_2":"Please enter a password",
        "register_rules_txt_3":"Please type password.",
        "register_rules_txt_4":"At least one special character",
        "register_rules_txt_5":"minimum 10 characters",
        "register_rules_txt_6":"Email must be valid",
        "register_rules_txt_7":"invalid civil id",
        "text_dialog_calc_text_1":"Calculate detailed reports",
        "text_dialog_calc_text_2":"Calculate optimization from alternative",
        "text_dialog_calc_text_3":"",
        "tooltip_detailed_btn_text_1":"Generate detailed reports",
        "tooltip_detailed_btn_text_2":"Optimize alternatives",
        "tooltip_detailed_btn_text_3":"Files link",
        "tooltip_home_btn_text_1":"Calc files",
        "area_is_way_too_big_warning_text":"The area exceeds the limit allowed by WWCALC. Please go back a step and redefine a boundary area, needs to be smaller than 5,000 km². ",
        "area_is_text":"The area is ",
        "capex_dialog_text_btn_1":"Change",
        "capex_dialog_text_btn_2":"Cancel",
        "alert_text_list_curves_txt_1":"The the number of curves must be equal to te number of points on the line string:  \nList curves sent: ",
        "alert_text_list_curves_txt_2":"\nNumber of curves: ",
        "countries_list": [
            'Afghanistan',
            'Albania',
            'Algeria',
            'Andorra',
            'Angola',
            'Antigua and Barbuda',
            'Argentina',
            'Armenia',
            'Austria',
            'Azerbaijan',
            'Bahrain',
            'Bangladesh',
            'Barbados',
            'Belarus',
            'Belgium',
            'Belize',
            'Benin',
            'Bhutan',
            'Bolivia',
            'Bosnia and Herzegovina',
            'Botswana',
            'Brazil',
            'Brunei',
            'Bulgaria',
            'Burkina Faso',
            'Burundi',
            'Cabo Verde',
            'Cambodia',
            'Cameroon',
            'Canada',
            'Central African Republic',
            'Chad',
            'Channel Islands',
            'Chile',
            'China',
            'Colombia',
            'Comoros',
            'Congo',
            'Costa Rica',
            "Côte d'Ivoire",
            'Croatia',
            'Cuba',
            'Cyprus',
            'Czech Republic',
            'Denmark',
            'Djibouti',
            'Dominica',
            'Dominican Republic',
            'DR Congo',
            'Ecuador',
            'Egypt',
            'El Salvador',
            'Equatorial Guinea',
            'Eritrea',
            'Estonia',
            'Eswatini',
            'Ethiopia',
            'Faeroe Islands',
            'Finland',
            'France',
            'French Guiana',
            'Gabon',
            'Gambia',
            'Georgia',
            'Germany',
            'Ghana',
            'Gibraltar',
            'Greece',
            'Grenada',
            'Guatemala',
            'Guinea',
            'Guinea-Bissau',
            'Guyana',
            'Haiti',
            'Holy See',
            'Honduras',
            'Hong Kong',
            'Hungary',
            'Iceland',
            'India',
            'Indonesia',
            'Iran',
            'Iraq',
            'Ireland',
            'Isle of Man',
            'Israel',
            'Italy',
            'Jamaica',
            'Japan',
            'Jordan',
            'Kazakhstan',
            'Kenya',
            'Kuwait',
            'Kyrgyzstan',
            'Laos',
            'Latvia',
            'Lebanon',
            'Lesotho',
            'Liberia',
            'Libya',
            'Liechtenstein',
            'Lithuania',
            'Luxembourg',
            'Macao',
            'Madagascar',
            'Malawi',
            'Malaysia',
            'Maldives',
            'Mali',
            'Malta',
            'Mauritania',
            'Mauritius',
            'Mayotte',
            'Mexico',
            'Moldova',
            'Monaco',
            'Mongolia',
            'Montenegro',
            'Morocco',
            'Mozambique',
            'Myanmar',
            'Namibia',
            'Nepal',
            'Netherlands',
            'Nicaragua',
            'Niger',
            'Nigeria',
            'North Korea',
            'North Macedonia',
            'Norway',
            'Oman',
            'Pakistan',
            'Panama',
            'Paraguay',
            'Peru',
            'Philippines',
            'Poland',
            'Portugal',
            'Qatar',
            'Réunion',
            'Romania',
            'Russia',
            'Rwanda',
            'Saint Helena',
            'Saint Kitts and Nevis',
            'Saint Lucia',
            'Saint Vincent and the Grenadines',
            'San Marino',
            'Sao Tome & Principe',
            'Saudi Arabia',
            'Senegal',
            'Serbia',
            'Seychelles',
            'Sierra Leone',
            'Singapore',
            'Slovakia',
            'Slovenia',
            'Somalia',
            'South Africa',
            'South Korea',
            'South Sudan',
            'Spain',
            'Sri Lanka',
            'State of Palestine',
            'Sudan',
            'Suriname',
            'Sweden',
            'Switzerland',
            'Syria',
            'Taiwan',
            'Tajikistan',
            'Tanzania',
            'Thailand',
            'The Bahamas',
            'Timor-Leste',
            'Togo',
            'Trinidad and Tobago',
            'Tunisia',
            'Turkey',
            'Turkmenistan',
            'Uganda',
            'Ukraine',
            'United Arab Emirates',
            'United Kingdom',
            'United States',
            'Uruguay',
            'Uzbekistan',
            'Venezuela',
            'Vietnam',
            'Western Sahara',
            'Yemen',
            'Zambia',
            'Zimbabwe'
        ],
    }