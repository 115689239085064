import {apiAxios} from '../helpers/interceptors'
import store from '../store/index'

export default {
  state: {
    products: {},
    credit: 0,
    paymentMethods: [],
    balance: 0,
    customerId: undefined,
    paymentId: undefined,
    transactions: [],
    transactionsCurrency: [],
  },
  mutations: {
    products: (state, value) => state.products = value,
    credit: (state, value) => state.credit = value,
    paymentMethods: (state, value) => state.paymentMethods = value,
    balance: (state, value) => state.balance = value,
    customerId: (state, value) => state.customerId = value,
    paymentId: (state, value) => state.paymentId = value,
    transactions: (state, value) => state.transactions = value,
    transactionsCurrency: (state, value) => state.transactionsCurrency = value,
    reset: (state) => {
      Object.keys(state).forEach(key => {
        // console.log('reset ',key,': ',state[key])
        state[key]=undefined
      })
    }
  },
  getters: {
    products: state => state.products,
    credit: state => state.credit,
    creditPrice: state => state.credit.value,
    paymentMethods: state => state.paymentMethods,
    paymentMethod: state => state.paymentMethods.length>0,
    balance: state => state.balance,
    balanceFormatted: state => {
      try{return state.balance.toLocaleString()}
      catch (error){
        // console.log('balanceFormatted toLocaleString not formatted')
        return state.balance
      }
    },
    customerId: state => state.customerId,
    paymentId: state => state.paymentId,
    transactions: state => state.transactions,
    transactionsCurrency: state => state.transactionsCurrency,
    transactionsFormatted: state => {
      if(state.transactionsCurrency){
        let t = JSON.parse(JSON.stringify(state.transactionsCurrency))
        t.forEach(item => {item.date = item.date.split('T')[0]})
        return t
      }
    },
  },
  actions: {
    async getMonetize({ commit }) {
      try {
        const res = await apiAxios.get('/monetize/')
        // console.log('resposta monetize ', res)
        if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          let products = {}
          let credit = undefined
          res.data.products.forEach(function (item) {
            // console.log('listitem',item)
            if(item.product_type!=null){
              products[item.name] = item
            }
            else{
              if(item.name=="calc_credits"){
                credit = item
              }
            }
          })
          commit("products", products)
          commit("credit", credit)
          // commit("creditPrice", JSON.stringify(credit.value))
          commit("paymentMethods", res.data.payment_methods)
          // console.log('getMonetize balance',res.data.user.balance)
          commit("balance", res.data.user.balance)
          commit("customerId",res.data.user.customer)
          try{
            commit("paymentId",res.data.payment_methods[0].id)
          } catch (error) {
            console.log('No payment method')
          }
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async getTransactions({ commit }) {
      try {
        let res = await apiAxios.post('/monetize/transactions',{"skip": 0,"limit": 100})
        if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          commit('transactions', res.data)
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async formatTransactions({ commit }){
      var onGoing = 0;
      var data = this.getters.transactions
        for(let i=0;i<=data.length;i++){
          if(data[i]){
            data[i]['balance_new'] = new Intl.NumberFormat('pt-BR', { currency: 'BRL' }).format(data[i]['balance_new'])
            data[i]['value'] = new Intl.NumberFormat('pt-BR', { currency: 'BRL' }).format(data[i]['value'])
            onGoing = i
            if(onGoing === data.length-1){
              commit('transactionsCurrency', data)
            }
          }
        }
    },
    async buyCredits(context,quantity) {
      try {
        const payload = 
        {
          "quantity": quantity,
          "payment_id": this.getters.paymentId.toString(),
          "product": this.getters.credit["product"]
        }
        const res = await apiAxios.post('/monetize/buy_credit',payload)
        if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          // console.log('buyCredits balance',res.data.balance_new)
          context.commit('balance', res.data.balance_new)
          let transactions = this.getters.transactions
          transactions.unshift(res.data.transaction)
          context.commit('transactions', transactions)
          return true
        }
        return res.statusText
      } catch (error) {
        alert(error)
        console.log(error)
        return false
      }
    },
    async createCreditCard({commit}, payload) {
      try {
        const res = await apiAxios.post('monetize/card_payment_method',payload)
        if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          commit("paymentId",res.data.id)
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    removePaymentId({ commit }) {
      commit("paymentId",undefined)
    },
    async updateCreditCard(context, payload) {
      try {
        const res = await apiAxios.put('/monetize/card_payment_method',payload)
        if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          // context.commit("paymentId",res.data.id)
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async useCredits(context, {calcId,alternativeNumber}) {
      if(!this.getters.paymentId){
        return "You haven't registered a payment method!"
      }
      let res
      if(alternativeNumber){
        res = await apiAxios.get('/card_pay/'+this.getters.paymentId+'/'+calcId+'/'+alternativeNumber)
      }
      else{
        res = await apiAxios.get('/card_pay/'+this.getters.paymentId+'/'+calcId)
      }
      if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
      if(res.status==200){
      window.localStorage.setItem('service_status','stable')
        return true
      }
      return res.statusText
    },
    async chargeCharacterization(context, {calcId}){
      const res = await apiAxios.post('/card_pay_characterization/'+this.getters.paymentId+'/'+calcId,{'password': store.getters.cloudPassword})
      console.log('res: ', res)
      if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
      if(res.status==200){
      window.localStorage.setItem('service_status','stable')
        return true
      } else if(res.status!=200){
        console.log(res.statusText)
        return false
      }
    },
  }
}


