// import Home from '../views/Home.vue'

const homeRoutes = [
  // {
  //   path: '/welcome',
  //   name: 'welcome',
  //   component: () => import('../views/WelcomePage.vue')
  // },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/billing_dev',
    name: 'billing',
    component: () => import('../views/Billing.vue')
  },
  {
    path: '/billing',
    name: 'billingMercadoPago',
    component: () => import('../views/BillingMercadoPago.vue')
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('../views/Support.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/edit/railway/:id',
    name: 'editRailway',
    component: () => import('../views/calc/railway/AdjustPricesDefault.vue')
  },
  {
    path: '/edit/roadway/:id',
    name: 'editRoadway',
    component: () => import('../views/calc/roadway/AdjustPricesDefault.vue')
  },
  {
    path: '/update/railway/:id',
    name: 'updateRailway',
    component: () => import('../views/calc/railway/UpdateDefaultParams.vue')
  },
  {
    path: '/update/roadway/:id',
    name: 'updateRoadway',
    component: () => import('../views/calc/roadway/UpdateDefaultParams.vue')
  },
  {
    path: '/password-reset/:email/:reset_token',
    name: 'password-reset',
    component: () => import('../views/PasswordReset.vue')
  },
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    component: () => import('../views/TermsOfUse.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('../views/PrivacyPolicy.vue')
  },
  {
    path: '/terms-of-use-pt',
    name: 'terms-of-use-pt',
    component: () => import('../views/TermsOfUsept.vue')
  },
  {
    path: '/privacy-policy-pt',
    name: 'privacy-policy-pt',
    component: () => import('../views/PrivacyPolicypt.vue')
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('../views/Pricing.vue')
  },
  {
    path: '/outofservice',
    name: 'outofservice',
    component: () => import('../views/maintenancePage.vue')
  },
]

export default homeRoutes