import {apiAxios} from '../helpers/interceptors'

export default {
  state: {
    types: undefined,
    configDefaults: undefined,
    configs: undefined,
    sridDict: undefined,
    appVersion: process.env.PACKAGE_VERSION || '0',
    loadedStore: false,
    cloudConnected: false,
    statusHome: false,

    paramSelected: undefined,
    selectedCapex: undefined,
    selectedCapexDetails: undefined,
    selectedCurves: undefined,
    selectedExpropriation: undefined,
    selectedRailTypes: undefined,

    newDefault: undefined,
    newCapex: undefined,
    newCapexDetails: undefined,
    newCurves: undefined,
    newExpropriation: undefined,
    newRailTypes: undefined,
  },
  mutations: {
    paramSelected: (state, value) => state.paramSelected = value,
    selectedCapexDetails: (state, value) => state.selectedCapexDetails = value,
    selectedExpropriation: (state, value) => state.selectedExpropriation = value,
    selectedCapex: (state, value) => state.selectedCapex = value,
    selectedRailTypes: (state, value) => state.selectedRailTypes = value,
    selectedCurves: (state, value) => state.selectedCurves = value,
    newDefault: (state, value) => state.newDefault = value,
    newCapexDetails: (state, value) => state.newCapexDetails = value,
    newExpropriation: (state, value) => state.newExpropriation = value,
    newCapex: (state, value) => state.newCapex = value,
    newRailTypes: (state, value) => state.newRailTypes = value,
    newCurves: (state, value) => state.newCurves = value,
    loadedStore: (state, value) => state.loadedStore = value,
    statusHome: (state, value) => state.statusHome = value,
    cloudConnected: (state, value) => state.cloudConnected = value,
    configDefaults: (state, value) => state.configDefaults = value,
    configs: (state, value) => state.configs = value,
    addConfig (state, config) {state.configs[config.id] = config},
    deleteConfig (state, config) {state.configs[config.id] = undefined},
    reset: (state) => {
      Object.keys(state).forEach(key => {
        // console.log('reset ',key,': ',state[key])
        if(key!='appVersion'){
          state[key]=undefined
        }        
      })
    },
    sridDict: (state, value) => state.sridDict = value,
  },
  getters: {
    paramSelected: (state) => {
      return state.paramSelected
    },
    selectedCapexDetails: (state) => {
      return state.selectedCapexDetails
    },
    selectedExpropriation: (state) => {
      return state.selectedExpropriation
    },
    selectedCapex: (state) => {
      return state.selectedCapex
    },
    selectedRailTypes: (state) => {
      return state.selectedRailTypes
    },
    selectedCurves: (state) => {
      return state.selectedCurves
    },
    newDefault: (state) => {
      return state.newDefault
    },
    newCapexDetails: (state) => {
      return state.newCapexDetails
    },
    newExpropriation: (state) => {
      return state.newExpropriation
    },
    newCapex: (state) => {
      return state.newCapex
    },
    newRailTypes: (state) => {
      return state.newRailTypes
    },
    newCurves: (state) => {
      return state.newCurves
    },
    appVersion: (state) => {
      return state.appVersion
    },
    statusHome: (state) => {
      return state.statusHome
    },
    loadedStore: (state) => {
      return state.loadedStore
    },
    cloudConnected: (state) => {
      return state.cloudConnected
    },
    configDefaults: state => state.configDefaults,
    configDefaultsByType: (state) => (type) => {
      try {
        return Object.values(state.configDefaults).filter(config => config.type === type)
      } catch(error) {
        return []
      }
    },
    configsByType: (state) => (type) => {
      try {
        return Object.values(state.configs).filter(config => config.type === type)
      } catch(error) {
        return []
      }
    },
    configDefaultsById: (state) => (id) => {return state.configDefaults[id]},
    configs: state => state.configs,
    configsByTypeId: (state) => (id) => {
      try {
        return Object.values(state.configs).filter(config => config.type === id)
      } catch (error) {
        return []
      }
    },
    configById: (state) => (id) => {return state.configs[id]},
    sridDict: state => state.sridDict,
  },
  actions: {
    setStatusHome({commit}){
      commit('statusHome',true)
      return true
    },
    setLoadedStore({commit}){
      commit('loadedStore',true)
      return true
    },
    setCloudConnected({commit}){
      commit('cloudConnected',true)
      return true
    },
    async getConfigDefaults({ commit }) {
      try {
        const res = await apiAxios.get('type_detail')
        // console.log('resposta getConfigDefaults ', res)
        if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          let dict = {}
          res.data.forEach(item => {dict[item.id] = item})
          commit('configDefaults', dict)
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    updateSelectedStore({ commit }, capex) {
      commit('selectedCapex', capex);
    },
    updateSelectedDeprecated({ commit }) {
      var param = this.getters.paramSelected
      param.deprecated = false
      commit('paramSelected', param);
    },
    updateSelectedExpropriation({ commit }, expropiation) {
      commit('selectedExpropriation', expropiation);
    },
    async getUpdatedDefault({ commit }, data) {
      try {
        const res = await apiAxios.get('type_detail/'+data.id)
        if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          //Capex
          let capex = res.data.capex.CAPEX
          commit('selectedCapexDetails', res.data.capex.Details)
          let capexRows = []
          capex['Level'].forEach((level,idx) => {
            let dict = {
              'Code': capex['Code'][idx],
              'Code Level': capex['Code Level'][idx],
              'Code Up': capex['Code Up'][idx],
              'Level': capex['Level'][idx],
              'Description': capex['Description'][idx],
              'Risk': Math.round(capex['Risk'][idx]*10000)/100,
              'Unit': capex['Unit'][idx],
              'Unit Cost': capex['Unit Cost'][idx],
              'enable': true
            }

            if(dict['Unit'] == '% of CAPEX' || dict['Unit'] == '% of group' || dict['Unit'] == '% do CAPEX' || dict['Unit'] == '% do grupo'){
              dict['Unit Cost'] = Math.round(dict['Unit Cost']*10000)/100
            }
            capexRows.push(dict)
          })
          let expropriation = {'Protect Class': res.data.raster_budget.categories[0].subcategories['Protect Class']}
          let expropriationRows = []

          Object.keys(expropriation).forEach((group,group_idx) => {
            let code = group_idx+1
            if(code<10){code='0'+code}
            let group_code = code
            let title = {
              'Code': group_code,
              'Level': 1,
              'Description': group,
              'Cost': undefined
            }
            expropriationRows.push(title)
            expropriation[group].names.forEach((name,name_idx) => {
              let code = name_idx+1
              if(code<10){code='0'+code}
              let dict = {
                'Code': group_code+'.'+code,
                'Level': 3,
                'Description': name,
                'Cost': expropriation[group].values.base[name_idx]
              }
              expropriationRows.push(dict)
            })
          })
          commit('newExpropriation', expropriationRows)
          commit('newCapex', capexRows)
          if(res.data.type == 'railway'){
            //PARAMS
            //rail_types
            let railTypes = res.data.values_params.rail_types
            railTypes.model
            railTypes.boleto_c
            let railTypesRows = []
            railTypes.model.forEach((model,idx) => {
              let dict = {
                'Id': idx,
                'Model': model,
                'Boleto': railTypes.boleto_c[idx],
              }
              railTypesRows.push(dict)
            })
            commit('selectedRailTypes', railTypesRows)
          }

          //axis_geometry curves
          let curves = res.data.values_params.axis_geometry.list_curves
          let curveRows = []
          curves.forEach(item => {
            let dict = {
              'Radius': item[0],
              'Ls': item[1],
              'Penalty': item[2],
              'Superelevation': item[3],
              'Compensation': item[4],
            }
            curveRows.push(dict)
          })
          commit('selectedCurves', curveRows)

          //params
          let params = res.data.values_params
          params.axis_geometry.max_grade = (params.axis_geometry.max_grade*100).toFixed(2)
          params.axis_geometry.min_grade = (-params.axis_geometry.min_grade*100).toFixed(2)
          params.platform.platform_slope = params.platform.platform_slope*100
          // params.earthwork.perpolation_factor = params.earthwork.perpolation_factor*100
          // params.earthwork.shrinkage_factor = params.earthwork.shrinkage_factor*100
          params.earthwork.cut_enbankment_slope = params.earthwork.cut_enbankment_slope*100
          params.earthwork.second_cat_rate = (params.earthwork.second_cat_rate*100).toFixed(2)
          params.earthwork.third_cat_rate = params.earthwork.third_cat_rate*100
          params.earthwork.fill_enbankment_slope = params.earthwork.fill_enbankment_slope*100

          if(res.data.type == 'roadway'){
            if(!params['imgs']){
              params['imgs'] = {
                "aquisition": "Road_and_lanes_1_lane",
                "bridge": "bridge_section_1_lane",
                "cut": "cut_1_lane",
                "fill": "fill_1_lane",
                "roads": "Road_and_lanes_1_lane",
                "tunnel": "tunnel_section_1_lane",
                "structure_total_width": 33,
                "structure_lanes_total_width": 3.5,
                "has_central_barrier": "No",
                "asphalt_depht" : 0.13,
                "road_table_selected": {
                    "line": 2,
                    "col": 2,
                    "name": null
                },
                "roadway_table": [
                  ['Region',	'O',	'I',	'II',	'III', 'IV', ''],
                  ['',	'',	'',	'',	'',	'A', 'B'],
                  ['Plain field',	'540',	'375',	'375',	'230',	'230',	'125'],
                  ['Irregular field',	'345',	'210',	'170',	'125',	'125',	'50'],
                  ['Mountainous region',	'210',	'115',	'80',	'50',	'50',	'25']
                ],
              }
            }
          }

          commit('newDefault', res.data)
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async getConfigs({ commit }) {
      try {
        const res = await apiAxios.get('user_type_default')
        // console.log('resposta getConfigs ', res)
        if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          let dict = {}
          res.data.forEach(item => {dict[item.id] = item})
          commit('configs', dict)
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async getConfigById({ commit }, data) {
      try {
        const res = await apiAxios.get('user_type_default/'+data.id)
        if(res.status==500){
          window.localStorage.setItem('service_status','unstable')
        }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          
          //Capex
          let capex = res.data.capex.CAPEX
          commit('selectedCapexDetails', res.data.capex.Details)
          let capexRows = []
          capex['Level'].forEach((level,idx) => {
            let dict = {
              'Code': capex['Code'][idx],
              'Code Level': capex['Code Level'][idx],
              'Code Up': capex['Code Up'][idx],
              'Level': capex['Level'][idx],
              'Description': capex['Description'][idx],
              'Risk': Math.round(capex['Risk'][idx]*10000)/100,
              'Unit': capex['Unit'][idx],
              'Unit Cost': capex['Unit Cost'][idx],
              'enable': true
            }

            if(dict['Unit'] == '% of CAPEX' || dict['Unit'] == '% of group' || dict['Unit'] == '% do CAPEX' || dict['Unit'] == '% do grupo'){
              dict['Unit Cost'] = Math.round(dict['Unit Cost']*10000)/100
            }
            capexRows.push(dict)
          })
          let expropriation = {'Protect Class': res.data.raster_budget.categories[0].subcategories['Protect Class']}
          let expropriationRows = []

          Object.keys(expropriation).forEach((group,group_idx) => {
            let code = group_idx+1
            if(code<10){code='0'+code}
            let group_code = code
            let title = {
              'Code': group_code,
              'Level': 1,
              'Description': group,
              'Cost': undefined
            }
            expropriationRows.push(title)
            expropriation[group].names.forEach((name,name_idx) => {
              let code = name_idx+1
              if(code<10){code='0'+code}
              let dict = {
                'Code': group_code+'.'+code,
                'Level': 3,
                'Description': name,
                'Cost': expropriation[group].values.base[name_idx]
              }
              expropriationRows.push(dict)
            })
          })
          commit('selectedExpropriation', expropriationRows)
          commit('selectedCapex', capexRows)
          if(res.data.type == 'railway'){
            //PARAMS
            //rail_types
            let railTypes = res.data.values_params.rail_types
            railTypes.model
            railTypes.boleto_c
            let railTypesRows = []
            railTypes.model.forEach((model,idx) => {
              let dict = {
                'Id': idx,
                'Model': model,
                'Boleto': railTypes.boleto_c[idx],
              }
              railTypesRows.push(dict)
            })
            commit('selectedRailTypes', railTypesRows)
          }

          //axis_geometry curves
          let curves = res.data.values_params.axis_geometry.list_curves
          let curveRows = []
          curves.forEach(item => {
            let dict = {
              'Radius': item[0],
              'Ls': item[1],
              'Penalty': item[2],
              'Superelevation': item[3],
              'Compensation': item[4],
            }
            curveRows.push(dict)
          })
          commit('selectedCurves', curveRows)

          //params
          let params = res.data.values_params
          params.axis_geometry.max_grade = (params.axis_geometry.max_grade*100).toFixed(2)
          params.axis_geometry.min_grade = (-params.axis_geometry.min_grade*100).toFixed(2)
          params.platform.platform_slope = params.platform.platform_slope*100
          // params.earthwork.perpolation_factor = params.earthwork.perpolation_factor*100
          // params.earthwork.shrinkage_factor = params.earthwork.shrinkage_factor*100
          params.earthwork.cut_enbankment_slope = params.earthwork.cut_enbankment_slope*100
          params.earthwork.second_cat_rate = (params.earthwork.second_cat_rate*100).toFixed(2)
          params.earthwork.third_cat_rate = params.earthwork.third_cat_rate*100
          params.earthwork.fill_enbankment_slope = params.earthwork.fill_enbankment_slope*100

          //new items list
          params['structure']['max_bridge_height'] = 80

          if(res.data.type == 'roadway'){
            if(!params['imgs']){
              params['imgs'] = {
                "aquisition": "Road_and_lanes_1_lane",
                "bridge": "bridge_section_1_lane",
                "cut": "cut_1_lane",
                "fill": "fill_1_lane",
                "roads": "Road_and_lanes_1_lane",
                "tunnel": "tunnel_section_1_lane",
                "structure_total_width": 33,
                "structure_lanes_total_width": 3.5,
                "has_central_barrier": "No",
                "asphalt_depht" : 0.13,
                "road_table_selected": {
                    "line": 2,
                    "col": 2,
                    "name": null
                },
                "roadway_table": [
                  ['Region',	'O',	'I',	'II',	'III', 'IV', ''],
                  ['',	'',	'',	'',	'',	'A', 'B'],
                  ['Plain field',	'540',	'375',	'375',	'230',	'230',	'125'],
                  ['Irregular field',	'345',	'210',	'170',	'125',	'125',	'50'],
                  ['Mountainous region',	'210',	'115',	'80',	'50',	'50',	'25']
                ],
              }
            }
          }

          commit('paramSelected', res.data)
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async updateSelectedParams() {
      try {
        let valuesParamsCopy = JSON.parse(JSON.stringify(this.getters.paramSelected.values_params));

        //curves
        let currentCurves = this.getters.selectedCurves
        let curves = valuesParamsCopy.axis_geometry.list_curves
        Object.values(curves).forEach((item,idx) => {
          item[0] = currentCurves[idx].Radius*1
          item[1] = currentCurves[idx].Ls*1
          item[2] = currentCurves[idx].Penalty*1
          item[3] = currentCurves[idx].Superelevation/100
          item[4] = currentCurves[idx].Compensation*1
        })

        //grade
        valuesParamsCopy.axis_geometry.max_grade = valuesParamsCopy.axis_geometry.max_grade/100
        valuesParamsCopy.axis_geometry.min_grade = -valuesParamsCopy.axis_geometry.min_grade/100
        valuesParamsCopy.platform.platform_slope = valuesParamsCopy.platform.platform_slope/100
        // valuesParamsCopy.earthwork.perpolation_factor = valuesParamsCopy.earthwork.perpolation_factor/100
        // valuesParamsCopy.earthwork.shrinkage_factor = valuesParamsCopy.earthwork.shrinkage_factor/100
        valuesParamsCopy.earthwork.cut_enbankment_slope = valuesParamsCopy.earthwork.cut_enbankment_slope/100
        valuesParamsCopy.earthwork.second_cat_rate = valuesParamsCopy.earthwork.second_cat_rate/100
        valuesParamsCopy.earthwork.third_cat_rate = valuesParamsCopy.earthwork.third_cat_rate/100
        valuesParamsCopy.earthwork.fill_enbankment_slope = valuesParamsCopy.earthwork.fill_enbankment_slope/100

        //others
        let payload = {deprecated:false,values_params: valuesParamsCopy}

        const res = await apiAxios.patch("user_type_default/"+this.getters.paramSelected.id, payload)
        if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async createConfig(context,payload) {
      try {
        const res = await apiAxios.post('user_type_default', payload)
        if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          context.commit('addConfig', res.data)
          return {'id': res.data.id}
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async duplicateConfig(context,payload) {
      try {
        const res = await apiAxios.post('duplicate_user_type_default/'+payload.user_type_id+'/'+payload.currency+'/'+payload.tax+'/'+payload.name, payload)
        if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          context.commit('addConfig', res.data)
          // return {'id': res.data.id}
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async updateSelectedRasterBudget() {
      try {
        let selectedExpropriation = this.getters.selectedExpropriation
        let expropriation = {'Protect Class': this.getters.paramSelected.raster_budget.categories[0].subcategories['Protect Class']}

        let idx = 1
        Object.values(expropriation).forEach(group => {
          group.names.forEach((name,groupedIdx) => {
            group.values.base[groupedIdx] = parseFloat(selectedExpropriation[idx].Cost)
            idx=idx+1
          })
          idx=idx+1
        })

        let payload = {deprecated:false,raster_budget: this.getters.paramSelected.raster_budget}

        const res = await apiAxios.patch("user_type_default/" + this.getters.paramSelected.id, payload)
        if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async updateSelectedCapex() {
      try {
        let capexCopy = JSON.parse(JSON.stringify(this.getters.paramSelected.capex));
        
        let unitCost = [] 
        let risk = []
        let descriptions = []
        let unit = []
        Object.values(this.getters.selectedCapex).forEach(item => {
          if(item['Unit']=='% of CAPEX' || item['Unit']=='% of group' || item['Unit'] == '% do CAPEX' || item['Unit'] == '% do grupo'){
            unitCost.push(parseFloat(Math.round(item['Unit Cost']*100)/10000))
          }
          else{
            unitCost.push(parseFloat(item['Unit Cost']))
          }
          risk.push(parseFloat(Math.round(item.Risk*100)/10000))
          descriptions.push(item.Description)
          unit.push(item.Unit)
        })

        capexCopy.CAPEX['Unit Cost'] = unitCost
        capexCopy.CAPEX.Risk = risk
        capexCopy.CAPEX.Description = descriptions
        capexCopy.CAPEX.Unit = unit

        let payload = {deprecated:false,capex: capexCopy}

        const res = await apiAxios.patch("user_type_default/"+this.getters.paramSelected.id, payload)
        if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async updateConfig(context,payload) {
      try {
        const res = await apiAxios.patch('user_type_default/'+payload.id, payload)
        if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          context.commit('addConfig', res.data)
          return true
        }
        return res.statusText
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async deleteConfig(context,type_id) {
      try {
        let txt = 'user_type_default/'+type_id
        const res = await apiAxios.delete(txt)
        if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          context.commit('deleteConfig', type_id)
          return true
        }
        else{
          return res.statusText
        }
      } catch (error) {
        alert(error)
        console.log(error)
        return false
      }
    },
    async getSridDict({commit}) {
      try {
        const res = await apiAxios.get('srid')
        if(res.status==500){
                window.localStorage.setItem('service_status','unstable')
              }
        if(res.status==200){
        window.localStorage.setItem('service_status','stable')
          commit('sridDict', res.data)
          return true
        }
        return res.statusText
      } catch (error) {
        alert(error)
        console.log(error)
        return false
      }
    },
  }
}
