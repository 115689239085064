const calcRoutes = [
  {
    path: '/type/new_railway',
    name: 'new_railway',
    component: () => import('../views/calc/railway/NewRailway.vue')
  },
  {
    path: '/type/railway/:id',
    name: 'railway',
    component: () => import('../views/calc/railway/Railway.vue')
  },
  {
    path: '/type/new_roadway',
    name: 'new_roadway',
    component: () => import('../views/calc/roadway/CreateNewRoadway.vue')
  },
  {
    path: '/type/roadway/:id',
    name: 'roadway-id',
    component: () => import('../views/calc/roadway/Roadway.vue')
  },
  {
    path: '/type/new_hidrology',
    name: 'hidrology',
    component: () => import('../views/calc/Map.vue')
  },
  // {
  //   path: '/calc/hidrology/:id',
  //   name: 'hidrology-id',
  //   component: () => import('../views/calc/hidrology/hidrology.vue')
  // },
  {
    path: '/type/powerline',
    name: 'powerline',
    component: () => import('../views/calc/PowerLine.vue')
  },
  {
    path: '/type/duct',
    name: 'duct',
    component: () => import('../views/calc/Duct.vue')
  },
  {
    path: '/type/dam',
    name: 'dam',
    component: () => import('../views/calc/Dam.vue')
  },
  {
    path: '/type/riparianforest',
    name: 'riparianforest',
    component: () => import('../views/calc/RiparianForest.vue')
  },
  {
    path: '/type/allotment',
    name: 'allotment',
    component: () => import('../views/calc/Allotment.vue')
  },
  // {
  //   path: '/pdfReport',
  //   name: 'pdfreport',
  //   component: () => import('../views/PdfReport.vue')
  // },
]

export default calcRoutes