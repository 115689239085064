var env = process.env.VUE_APP_VAR_ENV;
let ENV_URLS = {}

if (env == "prd") {
    ENV_URLS = {
        "api": "https://api.wwcalc.com/calc",
        "apidownload": "https://api.wwcalc.com/calc",
        "auth" : "https://api.wwcalc.com/auth",
    }
}
else if (env == "dev") {
    ENV_URLS = {
        "api": "https://dev.wwcalc.com/calc",
        "apidownload": "https://dev.wwcalc.com/calc",
        "auth" : "https://dev.wwcalc.com/auth",
    }
}
else if (env == "hml") {
    ENV_URLS = {
        "api": "https://hml.wwcalc.com/calc",
        "apidownload": "https://hml.wwcalc.com/calc",
        "auth" : "https://hml.wwcalc.com/auth",
    }
}
else if (env == "local") {
    ENV_URLS = {
        "api": "http://localhost:8080/calc",
        "route": "http://localhost:8080/",
        "apidownload": "https://dev.wwcalc.com/calc",
        "auth" : "http://localhost:8080/auth",
    }
}
else {console.error("No env set! Using localhost for dev server.")}

export const URLS = ENV_URLS