import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store/index'
import interceptorsSetup from './helpers/interceptors'
// import './registerServiceWorker.js'
import VueLayers from 'vuelayers'
import router from './router/index.js'
import { VueMaskDirective } from 'v-mask'
import VueCryptojs from 'vue-cryptojs'

Vue.use(VueCryptojs)

Vue.directive('mask', VueMaskDirective);

Vue.use(VueLayers)

interceptorsSetup()

new Vue({
  router,
  vuetify,
  store,
  // i18n,
  render: h => h(App)
}).$mount('#app')
